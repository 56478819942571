import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../app.service';
import { catchError } from 'rxjs/operators';
import { UtilsService } from '../utils.service';
const { v4: uuidv4 } = require('uuid');
const myUUID = uuidv4();
import configration from '../../assets/config.json';
const { getPresetsEn, getPresetsEs } = require('../../assets/presets');
import { Router } from '@angular/router';

@Component({
  selector: 'app-getnumber',
  templateUrl: './getnumber.component.html',
  styleUrls: ['./getnumber.component.scss']
})

export class GetnumberComponent implements OnInit {
  @Input() isPopupOpen: boolean = false;
  @Output() close = new EventEmitter();
  public selectedNumberIndex: any;
  public selectedNumber: any;
  public regions: any;
  public message: any;
  public businessDetail: any;
  public choosenCharacter: any;
  public isLoading: boolean = false;
  public availableNumbers: any;
  public give_quick_answers: any = [];
  public transfer_call: any = [];
  public direct_caller_to_sites: any = [];
  public take_message: any = [];
  public lead_capture: any = [];
  public presets: any;
  public language: any;
  public url: any;
  public businessType: any;

  constructor(private router: Router, private appService: AppService, private utilsService: UtilsService) { }

  async ngOnInit() {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/performance') || this.url.startsWith('/es/dashboard/knowledge') || this.url.startsWith('/es/dashboard/call_logs') || this.url.startsWith('/es/dashboard/flows') || this.url.startsWith('/es/dashboard/new_path') || this.url.startsWith('/es/dashboard/settings')) {
      this.language = configration.es;
      this.presets = getPresetsEs();;
    } else {
      this.language = configration.en;
      this.presets = getPresetsEn();
    }

    
    const businessDetailsString = localStorage.getItem("businessdetails");
    const businesstypeString = localStorage.getItem("main_business_type_value") !== null 
    ? localStorage.getItem("main_business_type_value") 
    : localStorage.getItem("mainbusinessType");
   console.log('businesstypeString', businesstypeString);


    if(businesstypeString != null){
      this.businessType = businesstypeString
    }
    if (businessDetailsString !== null) {
      this.businessDetail = JSON.parse(businessDetailsString);
      console.log('businessDetail', this.businessDetail);
      this.fetchBusinessDetails();
      if (!this.businessDetail.agent_phone_number) {
        await this.getAvailableUSRegions();
      }
      
      await this.getAgentDetails();
    }
  }

  fetchBusinessDetails(): void {
    if (!this.businessDetail || !this.businessDetail.id) {
      console.error('Business details not available or missing ID.');
      return;
    }

    const business_id = this.businessDetail.id;
    console.log('Fetching business details for ID:', business_id);

    this.appService.getBusinessDetailsById(business_id).subscribe(
      (response) => {
        console.log('Business Details:', response);
        localStorage.setItem('main_business_type_value', response.data.businesses_by_pk.main_business_type);
        console.log('Stored main_business_type_value:', localStorage.getItem('main_business_type_value'));
      },
      (error) => {
        console.error('Error fetching business details:', error);
      }
    );
  }

  closePopup(): void {
    this.isPopupOpen = false;
    this.availableNumbers = null;
    this.close.emit();
  }

  async getAvailableUSRegions() {
    this.regions = await this.appService.getAvailableUSRegions().toPromise();
  }

  lookupForNumber(event: any) {
    this.isLoading = true;
    this.availableNumbers = null;
    if (event.target.value === this.language.select_country) {
      this.isLoading = false;
      return;
    }
    this.appService.getAvailablePhoneNumbers(event.target.value).pipe(
      catchError(error => {
        this.message = this.language.number_doesnt_exist;
        return error;
      })
    ).subscribe(res => {
      this.isLoading = false;
      this.message = null;
      this.availableNumbers = res;
    });
  }

  selectNumber(data: any, index: number) {
    this.selectedNumberIndex = index;
    this.selectedNumber = data;
  }

  async assignNumber(numberDetails: any) {
    let body = {
      id: this.businessDetail.id,
      agent_phone_number: numberDetails.agent_phone_number
    }
    await this.appService.addAgentNumberToBusiness(body).subscribe(async (res) => {
      this.utilsService.setItem("businessdetails", res.data.update_businesses_by_pk);
      await this.addTwilioBotDetails(numberDetails);
    })
  }

  async purchasePhoneNumber() {
    this.isLoading = true;

    let body = {
      phone_number: this.selectedNumber.phoneNumber,
      language: this.language.language
    }
    await this.appService.purchasePhoneNumber(body).subscribe(async (res) => {
      await this.assignNumber(res);
      this.isLoading = false;
      return res;
    })
  }

  async addTwilioBotDetails(numberDetails: any) {
    for (let pre of this.presets) {
      let training_set;
      console.log(pre.action)
      switch (pre.action) {
        case 'Give Quick Answer':
          training_set = {
            question: pre.question,
            answer: pre.answer + this.language.how_else_can_i_help,
            id: pre.id
          };
          this.give_quick_answers.push(training_set);
          break;
        case 'Transfer Call':
          training_set = {
            user_says: pre.question,
            question: this.language.connect_to_someone_of_help,
            where_agent_will_transfer: pre.phone_number,
            id: pre.id,
            tool_function_name_to_call: "transferCall",
          };
          this.transfer_call.push(training_set);
          break;
        case 'Lead Capture':
          let body = {
            questions: pre.form,
            trigger: pre.question,
            form_name: pre.task_name,
            language: this.language.language
          };
          const leadCaptureRes = await this.appService.leadCaptureForBotTrainingBody(body).toPromise();
          training_set = leadCaptureRes;
          training_set.id = pre.id;
          this.lead_capture.push(training_set);
          break;
        case 'Direct Caller to Website':
          training_set = {
            form_type: "direct_caller_to_sites",
            id: pre.id,
            instructions: this.language.follow_instructions,
            steps: [
              {
                id: "1",
                key: "sms_consent",
                question: this.language.over_sms,
                responses: {
                  tool_function_name_to_call: "direct_caller_to_sites",
                  website_url: pre.website_url ? pre.website_url : "yoursiteurl.com"
                }
              }
            ],
            trigger: pre.question
          };
          this.direct_caller_to_sites.push(training_set);
          break;
        case 'Take Message':
          training_set = {
            client_contact_number: pre.phone_number,
            form_type: "take_message",
            id: pre.id,
            instructions: this.language.follow_instructions,
            steps: [
              {
                id: "1",
                question: this.language.whats_the_msg,
                responses: { "user_gave_proper_response": { "next_step": "2" } }
              },
              {
                id: "2",
                question: this.language.send_over_sms,
                responses: { "tool_function_name_to_call": "takeMessage" }
              }
            ],
            trigger: pre.question
          };
          this.take_message.push(training_set);
          break;
      }
    }
    let state = "";
    if (this.businessDetail.business_address) {
      state = await this.utilsService.extractStateFromAddress(this.businessDetail.business_address);
    }
    const body = {
      business_id: this.businessDetail.id,
      forward_call_number: this.businessDetail.business_phone,
      agent_phone_number: numberDetails.agent_phone_number,
      business_name: this.businessDetail.business_name,
      voice_lang: this.language.language == 'Spanish' ? this.choosenCharacter.spanish_voice_lang : this.choosenCharacter.voice_lang,
      voice_name: this.language.language == 'Spanish' ? this.choosenCharacter.spanish_voice_name : this.choosenCharacter.voice_name,
      welcome_message: `${this.language.thank_you_for_calling} ${this.businessDetail.business_name}. ${this.language.this_is} ${this.choosenCharacter.agent_name} ${this.language.assist}`,
      training_data: {
        direct_caller_to_sites: this.direct_caller_to_sites,
        general_instruction: this.businessType == 'Restaurant'|| this.businessType == 'Restaurante' ? this.language.general_instruction : this.language.other_service_general_instruction,
        give_quick_answers: this.give_quick_answers,
        lead_capture: this.lead_capture,
        menu: {},
        restaurant_name: this.businessDetail.business_name,
        take_message: this.take_message,
        transfer_call: this.transfer_call,
        working_hours: this.businessDetail.business_hours ? {
          "answer": JSON.stringify(this.businessDetail.business_hours),
          "question": this.language.store_operating_hours
        } : {}
      },
      message_service_id: numberDetails.message_sid,
      time_zone: state ? await this.utilsService.getTimezone(state).timezone + " " + `(${state})` : "",
      operating_hours: this.businessDetail.business_hours ? this.utilsService.updateTimingArrayTo24hrs(this.businessDetail.business_hours) : {}
    };
    await this.appService.addTwilioBotDetails(body).subscribe(async (res) => {
      const record = {
        business_id: this.businessDetail.id,
        data: this.presets
      };
      await this.appService.addNewTrainingChatbotrecord(record).subscribe(res => {
        this.closePopup();
        window.location.reload();
      });
    });
  }

  getAgentDetails() {
    this.appService.getAgentDetailsById(this.businessDetail.agent_id).subscribe(res => {
      this.choosenCharacter = res.data.agents_by_pk;
    });
  }

  generateUniqueId() {
    return uuidv4();
  }
}