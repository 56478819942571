import { Component, ElementRef, HostListener } from '@angular/core';
import { AppService } from '../app.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../utils.service';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss']
})

export class FlowComponent {
  questionForm: FormGroup;
  trainAgent: FormGroup;

  public openDropdownIndex: number = -1;
  public openedDropdown: boolean = false
  public isEditMessagePopupOpen: boolean = false
  public businessDetails: any;
  public audios: any;
  public editContent: any;
  public errorMessage: any;
  public twilioBotDetails: any;
  public welcomeMessage = ""
  public language: any;
  public url: any;

  constructor(private router: Router, private appService: AppService, private fb: FormBuilder, private toastr: ToastrService, private utilsService: UtilsService, private elementRef: ElementRef) {
    this.questionForm = this.fb.group({
      questions: this.fb.array([])
    });
    this.trainAgent = this.fb.group({
      id: [''],
      task_name: [''],
      action: [''],
      question: [''],
      answer: [''],
      website_url: [''],
      phone_number: [''],
      form: [[]]
    });
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/flows')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    

    this.businessDetails = this.utilsService.getItem('businessdetails');
    // this.businessDetails.poc_number_array = this.businessDetails.poc_number_array.filter((number: any) => number.trim() !== "");
    if (this.businessDetails) {
      this.loadBotDetails();
      this.loadTwilioBotDetails();
      
    }
  }

  private loadBotDetails(): void {
    this.appService.getBotDetails(this.businessDetails.id, false).subscribe(
      (res) => {
        this.audios = res.data.chatbot_training_data[0].data;
      },
      (error) => {
        console.error('Error fetching bot details:', error);
      }
    );
  }
  private loadTwilioBotDetails(): void {
    this.appService.getTwilioBotDetails(this.businessDetails.agent_phone_number).subscribe(
      (res) => {
        this.twilioBotDetails = res.data.twilio_by_pk;
      },
      (error) => {
        console.error('Error fetching Twilio bot details:', error);
      }
    );
  }


  async deleteTrainedData(indexToDelete: number, value: any) {
    this.audios.splice(indexToDelete, 1);

    let botIndex;
    switch (value.action) {
      case 'Give Quick Answer':
        botIndex = this.twilioBotDetails.training_data.give_quick_answers.findIndex((obj: any) => obj.id == value.id);
        this.twilioBotDetails.training_data.give_quick_answers.splice(botIndex, 1);
        break;
      case 'Direct Caller to Website':
        botIndex = this.twilioBotDetails.training_data.direct_caller_to_sites.findIndex((obj: any) => obj.id == value.id);
        this.twilioBotDetails.training_data.direct_caller_to_sites.splice(botIndex, 1);
        break;
      case 'Take Message':
        botIndex = this.twilioBotDetails.training_data.take_message.findIndex((obj: any) => obj.id == value.id);
        this.twilioBotDetails.training_data.take_message.splice(botIndex, 1);
        break;
      case 'Transfer Call':
        botIndex = this.twilioBotDetails.training_data.transfer_call.findIndex((obj: any) => obj.id == value.id);
        this.twilioBotDetails.training_data.transfer_call.splice(botIndex, 1);
        break;
      case 'Lead Capture':
        botIndex = this.twilioBotDetails.training_data.lead_capture.findIndex((obj: any) => obj.id == value.id);
        this.twilioBotDetails.training_data.lead_capture.splice(botIndex, 1);
        break;
    }

    await this.appService.updateTwilioBotDetails(this.twilioBotDetails.phone_number, this.twilioBotDetails.training_data).subscribe(res => {
      this.twilioBotDetails = res.data.update_twilio_by_pk;
    });

    await this.appService.updateBotDetails(this.audios, this.businessDetails.id).subscribe(res => {
      this.audios = res.data.update_chatbot_training_data.returning[0].data;
      this.openedDropdown = false;
    });
  }

  editTrainedFlow(content: any) {
    this.openDropdown(-1);
    this.questions.clear();
    this.editContent = content;
    this.trainAgent.setValue({
      id: this.editContent.id,
      task_name: this.editContent.task_name,
      action: this.editContent.action,
      question: this.editContent.question,
      answer: this.editContent.answer,
      website_url: this.editContent.website_url,
      phone_number: this.editContent.phone_number,
      form: this.editContent.form
    });
    this.trainAgent.get('action')?.disable();
    if (this.editContent.form.length) {
      this.editContent.form.forEach((q: any) => {
        this.addQuestionWithData(q.question, q.type);
      });
    }
  }

  get questions(): FormArray {
    return this.questionForm.get('questions') as FormArray;
  }
  newQuestion(question: string = '', type: string = ''): FormGroup {
    return this.fb.group({
      question: [question, Validators.required],
      type: [type, Validators.required]
    });
  }
  addQuestion() {
    this.questions.push(this.newQuestion());
  }
  addQuestionWithData(question: string = '', type: string = '') {
    this.questions.push(this.newQuestion(question, type));
  }
  removeQuestion(index: number) {
    this.questions.removeAt(index);
  }

  openDropdown(index: number) {
    this.openedDropdown = !this.openedDropdown
    this.openDropdownIndex = index;
  }

  goback() {
    this.editContent = null
  }

  async finalSaveAddMore() {
    this.errorMessage = "";
    this.trainAgent.value.action = this.editContent.action;
    this.trainAgent.value.form = this.questionForm.value.questions;
    let index = this.audios.findIndex((obj: any) => obj.id === this.editContent.id);
    this.audios[index] = this.trainAgent.value;

    let botIndex;
    let body;
    let requestBody;

    switch (this.trainAgent.value.action) {
      case 'Give Quick Answer':
        botIndex = this.twilioBotDetails.training_data.give_quick_answers.findIndex((obj: any) => obj.id == this.editContent.id);
        body = {
          "question": this.trainAgent.value.question,
          "answer": this.trainAgent.value.answer + this.language.how_else_can_i_help,
          "id": this.editContent.id
        }
        this.twilioBotDetails.training_data.give_quick_answers[botIndex] = body;
        break;
      case 'Transfer Call':
        botIndex = this.twilioBotDetails.training_data.transfer_call.findIndex((obj: any) => obj.id == this.editContent.id);
        body = {
          "user_says": this.trainAgent.value.question,
          "question": this.trainAgent.value.answer,
          "where_agent_will_transfer": this.trainAgent.value.phone_number,
          "id": this.editContent.id,
          "tool_function_name_to_call": "transferCall"
        }
        this.twilioBotDetails.training_data.transfer_call[botIndex] = body;
        break;
      case 'Lead Capture':
        botIndex = this.twilioBotDetails.training_data.lead_capture.findIndex((obj: any) => obj.id == this.editContent.id);
        requestBody = {
          questions: this.trainAgent.value.form,
          trigger: this.trainAgent.value.question,
          form_name: this.trainAgent.value.task_name,
          language: this.language.language
        }
        body = await this.appService.leadCaptureForBotTrainingBody(requestBody).toPromise();
        body.id = this.editContent.id;
        this.twilioBotDetails.training_data.lead_capture[botIndex] = body;
        break;
      case 'Direct Caller to Website':
        botIndex = this.twilioBotDetails.training_data.direct_caller_to_sites.findIndex((obj: any) => obj.id == this.editContent.id);
        body = {
          "form_type": "direct_caller_to_sites",
          "id": this.editContent.id,
          "instructions": this.language.follow_instructions,
          "steps": [
            {
              "id": "1",
              "key": "sms_consent",
              "question": this.language.over_sms,
              "responses": {
                "tool_function_name_to_call": "direct_caller_to_sites",
                "website_url": this.trainAgent.value.website_url
              }
            }
          ],
          "trigger": this.trainAgent.value.question
        }
        this.twilioBotDetails.training_data.direct_caller_to_sites[botIndex] = body;
        break;
      case 'Take Message':
        botIndex = this.twilioBotDetails.training_data.take_message.findIndex((obj: any) => obj.id == this.editContent.id);
        body = {
          "client_contact_number": this.trainAgent.value.phone_number,
          "form_type": "take_message",
          "id": this.editContent.id,
          "instructions": this.language.follow_instructions,
          "steps": [
            {
              "id": "1",
              "question": this.language.whats_the_msg,
              "responses": { "user_gave_proper_response": { "next_step": "2" } }
            },
            {
              "id": "2",
              "question": this.language.send_over_sms,
              "responses": { "tool_function_name_to_call": "takeMessage" }
            }
          ],
          "trigger": this.trainAgent.value.question
        }
        this.twilioBotDetails.training_data.take_message[botIndex] = body;
        break;
    }

    await this.appService.updateTwilioBotDetails(this.twilioBotDetails.phone_number, this.twilioBotDetails.training_data).toPromise();

    let reqbody = {
      business_id: this.businessDetails.id,
      data: this.audios
    }
    await this.appService.updateChatBotTrainingData(reqbody).subscribe(() => {
      this.toastr.success(this.language.saved_success_toastr);
      window.location.reload();
    });
  }

  editMessagePopup() {
    this.isEditMessagePopupOpen = true;
    this.welcomeMessage = this.twilioBotDetails.welcome_message;

    console.log(this.welcomeMessage)
  }
  closePopup() {
    this.isEditMessagePopupOpen = false;
  }

  updateWelcomeMessage() {
    const body = {
      welcome_message: this.welcomeMessage,
      agent_phone_number: this.businessDetails.agent_phone_number
    }

    this.appService.updateWelcomeMessage(body).subscribe((res) => {
      this.twilioBotDetails = res.data.update_twilio_by_pk;
      this.closePopup();
    })
  }
}